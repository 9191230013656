"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "clearCacheForTesting", {
  enumerable: true,
  get: function () {
    return _userInfoState.clearCacheForTesting;
  }
});
exports.default = void 0;
exports.resetEarlyRequestPromiseForTesting = resetEarlyRequestPromiseForTesting;
Object.defineProperty(exports, "transformHublessUserInfoResponse", {
  enumerable: true,
  get: function () {
    return _userInfoInternal.transformHublessUserInfoResponse;
  }
});
Object.defineProperty(exports, "transformUserInfoResponse", {
  enumerable: true,
  get: function () {
    return _userInfoInternal.transformUserInfoResponse;
  }
});
exports.userInfoWithDelegatedOptions = exports.userInfoSync = exports.userInfoSafe = void 0;
var _objectDestructuringEmpty2 = _interopRequireDefault(require("@babel/runtime/helpers/objectDestructuringEmpty"));
var _objectWithoutPropertiesLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutPropertiesLoose"));
var _loginVerifyClient = _interopRequireDefault(require("./clients/loginVerifyClient"));
var _userInfoState = require("./userInfo-state");
var _events = require("./helpers/events");
var _authMocked = require("./helpers/authMocked");
var _newRelicReporting = require("./helpers/newRelicReporting");
var _userInfoInternal = require("./userInfo-internal");
const _excluded = ["cached"];
const getUserInfo = options => {
  const {
      cached = true
    } = options,
    otherOptions = (0, _objectWithoutPropertiesLoose2.default)(options, _excluded);

  // don't use early requester if we're trying to refresh the data
  if (!cached && otherOptions.externalResponse) {
    delete otherOptions.externalResponse;
  }
  const memoizedPromise = (0, _userInfoState.getMemoizedPromise)();
  if (cached && memoizedPromise) {
    otherOptions.recycledPromise = memoizedPromise;
  }
  const loginVerifyCall = (0, _loginVerifyClient.default)('/login-verify', otherOptions);
  if (!cached || !memoizedPromise) {
    (0, _userInfoState.setMemoizedPromise)(loginVerifyCall);
  }
  return loginVerifyCall.then(({
    data
  }) => data);
};
let earlyRequestPromise;
function resetEarlyRequestPromiseForTesting() {
  earlyRequestPromise = undefined;
}
const NO_EARLY_QUICK_FETCH_FOUND_MESSAGE = 'No quick-fetch early login-verify request found';
const earlyRequest = () => {
  if (!earlyRequestPromise) {
    earlyRequestPromise = new Promise((resolve, reject) => {
      const request = window.quickFetch && window.quickFetch.getRequestStateByName('api-verify');
      if (!request) {
        reject(new Error(NO_EARLY_QUICK_FETCH_FOUND_MESSAGE));
        (0, _newRelicReporting.setCustomAttribute)('earlyRequesterRequestNotFound', 'true');
        (0, _newRelicReporting.setCustomAttribute)('earlyRequesterFinished', 'false');
        return;
      }
      const earlyRequesterFinished = request.finished;
      request.whenFinished(data => {
        (0, _newRelicReporting.setCustomAttribute)('earlyRequesterFinished', `${Boolean(earlyRequesterFinished)}`);
        if (window.performance && typeof window.performance.getEntriesByName === 'function' && window.performance.getEntriesByName(_newRelicReporting.MEASURE_API_VERIFY_TIME).length) {
          (0, _newRelicReporting.setCustomAttribute)('earlyRequesterApiTime', window.performance.getEntriesByName(_newRelicReporting.MEASURE_API_VERIFY_TIME)[0].duration);
        }
        return resolve(data);
      });
      request.onError(xhr => {
        reject(new Error(`[hub-http] EarlyRequester token refresh attempt failed with status ${xhr.status}: ${xhr.statusText}`));
      });
    });
  }
  return earlyRequestPromise;
};
const get = options => {
  const authMocked = (0, _authMocked.isAuthMocked)(options);
  // when earlyRequester fails, pass control over to the login verify client to try again
  const earlyRequestFallback = reason => {
    if (reason && (
    // Our recommendation is to test the app without quick-fetch
    // We want to reduce console noise by filtering out "NO_EARLY_QUICK_FETCH_FOUND_MESSAGE" errors.
    !authMocked || reason.message !== NO_EARLY_QUICK_FETCH_FOUND_MESSAGE)) {
      // eslint-disable-next-line no-console
      console.error(reason.message);
    }
    return getUserInfo(options);
  };

  // when earlyRequester succeeds, send a dummy response in the login verify's client for processing
  const dummyResponse = response => ({
    status: 200,
    statusText: 'OK',
    data: response
  });
  const request = earlyRequest().then(response => getUserInfo(Object.assign({}, options, {
    externalResponse: dummyResponse(response)
  }))).catch(earlyRequestFallback);
  return request.then(response => {
    const info = (0, _userInfoInternal.transformUserInfoResponse)(response);
    if (window.performance && typeof window.performance.mark === 'function' && typeof window.performance.measure === 'function' && typeof window.performance.getEntriesByName === 'function') {
      window.performance.mark(_newRelicReporting.MARK_USER_INFO_SUCCESS);
      window.performance.measure(_newRelicReporting.MEASURE_USER_INFO_TIME, _newRelicReporting.MARK_USER_INFO_START, _newRelicReporting.MARK_USER_INFO_SUCCESS);
      const userInfoTime = window.performance.getEntriesByName(_newRelicReporting.MEASURE_USER_INFO_TIME).length ? window.performance.getEntriesByName(_newRelicReporting.MEASURE_USER_INFO_TIME)[0].duration : -1;
      (0, _newRelicReporting.setCustomAttribute)('userInfoTime', userInfoTime);
    }
    (0, _events.triggerEvent)('hubspot:userinfochange', info);
    return info;
  });
};
const userInfo = (options = {}) => {
  const others = Object.assign({}, ((0, _objectDestructuringEmpty2.default)(options), options));
  if (window.performance && typeof window.performance.mark === 'function') {
    window.performance.mark(_newRelicReporting.MARK_USER_INFO_START);
  }
  return get(others).then(data => {
    (0, _userInfoState.setMemoizedInfo)(data);
    return data;
  });
};
const userInfoWithDelegatedOptions = ({
  cached,
  ignoreRedirect,
  safeMode,
  allowSuspended
}) => userInfo({
  cached,
  ignoreRedirect,
  safeMode,
  allowSuspended
});
exports.userInfoWithDelegatedOptions = userInfoWithDelegatedOptions;
const userInfoSafe = options => userInfo(Object.assign({}, options, {
  safeMode: true
}));
exports.userInfoSafe = userInfoSafe;
const userInfoSync = () => {
  const memoizedInfo = (0, _userInfoState.getMemoizedInfo)();
  if (!memoizedInfo) {
    throw new Error('User info has not be loaded yet. Did you call userInfoSync before the userInfo promise resolved?');
  }
  return memoizedInfo;
};

// TODO this can be removed my migrating all existing consumers to import from `userInfo-state.js` and adding the entry to "publicModules"
exports.userInfoSync = userInfoSync;
var _default = exports.default = userInfo;