"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withStaticAppInfo = exports.ensureStaticAppInfo = void 0;
var _core = require("./core");
var _url = require("../helpers/url");
var params = _interopRequireWildcard(require("../helpers/params"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
const formatVersion = version => {
  if (version == null) {
    return 'unknown';
  }
  if (version === 'static') {
    return 'dev';
  }
  return version.replace('static-', '');
};
const withStaticAppInfo = options => options.appInfo ? (0, _core.header)('X-HubSpot-Static-App-Info', `${options.appInfo.name}@${formatVersion(options.appInfo.version)}`)(options) : options;
exports.withStaticAppInfo = withStaticAppInfo;
const ensureStaticAppInfo = options => {
  if (!options.appInfo) return options;
  const queries = params.parse((0, _url.parseUrl)(options.url).query);
  return (0, _core.query)({
    hs_static_app: queries.hs_static_app || options.appInfo.name,
    hs_static_app_version: queries.hs_static_app_version || `${formatVersion(options.appInfo.version)}`
  })(options);
};
exports.ensureStaticAppInfo = ensureStaticAppInfo;