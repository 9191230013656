"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAuthMocked = isAuthMocked;
var _authMocked = require("../internal/authMocked");
/**
 * We cannot solely rely on the options for quick-fetch early request
 */
function isAuthMocked(options = {}) {
  if (options.mockAuth !== undefined) return Boolean(options.mockAuth);
  return (0, _authMocked.getMockAuth)();
}