"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("../index");
var _promiseClient = _interopRequireDefault(require("../adapters/promiseClient"));
var core = _interopRequireWildcard(require("../middlewares/core"));
var hubapi = _interopRequireWildcard(require("../middlewares/hubapi"));
var debug = _interopRequireWildcard(require("../middlewares/debug"));
var user = _interopRequireWildcard(require("../middlewares/user"));
var _params = require("../helpers/params");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = exports.default = (0, _promiseClient.default)((0, _index.createStack)(core.services, hubapi.defaults, user.recyclePromise, debug.allowTimeoutOverride, user.portalIdBody, core.bodyType('application/x-www-form-urlencoded', _params.stringify), user.hubUserInfoEndpointTest, core.httpsOnly, debug.rewriteUrl, core.reportOptionsError, user.logoutOnUnauthorizedOrForbidden, core.enableMigrationCheckBypass, core.redirectOnMigrationInProgress, core.redirectOnPortalMoved, user.retryOnError, core.jsonResponse, user.redirectSuspendedUsers));
module.exports = exports.default;