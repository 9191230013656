"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enableMockAuth = enableMockAuth;
exports.getMockAuth = getMockAuth;
let mockAuth = false;
function enableMockAuth() {
  mockAuth = true;
}
function getMockAuth() {
  return mockAuth;
}